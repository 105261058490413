.choose {
    margin-bottom: 4rem;
}

.container {
    display: flex;
    align-items: center;
}

.choose .title {
    text-align: center;
    margin: 0 auto 80px;
    position: relative;
    line-height: 60px;
    color: #555;
}

.choose .title::after {
    content: '';
    background-color: #00bdfe;
    width: 80px;
    height: 5px;
    border: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.window-container {
    flex: 0.5;
    background-color: #00bdfe;
    border-radius: 5px;
    padding: 15px;
}

.window {
    padding: 10px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.window h1 {
    color: #00bdfe;
    font-weight: bolder;
    font-size: 70px;
    text-align: center;
}

.window h4 {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.info-container {
    flex: 1.5;
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    gap: 20px;
    margin-left: 20px;
}

.info {
    /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px; */
    text-align: left;
}

.info h2 {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
}

.info p {
    font-size: 14px;
}

@media (max-width: 800px) {
    .window-container {
        flex: 0.5;
        background-color: #00bdfe;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 60px;
    }

    .info-container {
        /*flex: 1.5;*/
        display: grid;
        grid-template-columns: auto;
        /* background-color: #2196F3; */
        gap: 20px;
        margin-left: 20px;
    }

    .info {
        margin-bottom: 20px;
    }

    .info > h2 {
        font-size: 20px;
    }

    .info > svg {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
    }

}