.footer {
    width: 100%;
    padding: 2rem 0;
    /*background-color: #ADD8E6;*/
    background-color: #b1e6f8;
}

.footer .container {
    /* display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.2rem; */
    display: flex;
}

.footer .col {
    margin: auto;
    text-align: left;
    flex: 1;
}

.footer ._col-2 {
    /* margin: 0 50px; */
    text-align: left;
    flex: 3;
}

.footer .col img {
    max-width: 300px;
}

.footer ._col-2 span {
    display: flex;
    align-items: center;
}

.footer h3 {
    margin: .8rem 0;
    color: #555;
}

.footer p {
    margin: .4rem 0;
    color: #555;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #555;
  }

.footer .social {
    text-align: center;
}

.footer .created {
    text-align: center;
}

.footer .created p {
    margin-top: 2rem;
    font-size: large;
    color: #555;
    font-weight: 600;
    letter-spacing: 2;
}

.footer .icon {
    margin-right: 10px;
    color: #555;
}

@media screen and (max-width: 940px) {
    .footer .container {
        grid-template-columns: repeat(2,1fr);
        gap: .5rem;
    }

    .footer .icon {
        margin: 1rem;
    }
}