.navbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 90px;
  background-color: #b1e6f8;;
  /* background: none; */
}

.navbar ._container {
  max-width: 1080px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.navbar ._container span {
  display: flex;
  align-items: center;
}

.first {
  color: #00bdfe;
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 1.3px;
}

.second {
  color: #c21807;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 1.3px;
}

.navbar ._container img {
  width: 130px;
}

.navbar span {
  color: #5451e5;
}

.navbar .nav-menu {
  /* display: flex; */
  margin-left: auto;
}

.navbar .nav-menu ul {
  display: flex;
  padding: 0;
}

.navbar .nav-menu li>a:hover {
  /* border-bottom: 4px solid #555; */
  /* color: #00bdfe; */
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease-out;

}

.navbar li {
  padding: 1rem;
}

.navbar .nav-menu li a {
  color: #555;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

.navbar .icon {
  font-size: 1.5rem;
}

@media screen and (max-width: 900px) {
  .hamburger {
    display: block;
  }

  .navbar ._container .nav-menu ul {
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    display: none;
  }

  .navbar ._container .nav-menu li {
    text-align: center;
    margin: 0;
  }

  .navbar ._container .nav-menu li a {
    color: #555;
    width: 100%;
    padding: 1.5rem 0;
  }

  .navbar ._container .nav-menu li a:hover {
    background-color: #eee;
  }

  .navbar ._container .nav-menu.active ul {
    display: block;
  }
}
