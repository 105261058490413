.services {
    padding-top: 4rem;
    margin-bottom: 4rem;
    background-color: #f5f5f5;
}

.services .title {
    text-align: center;
    margin: 0 auto 80px;
    position: relative;
    line-height: 60px;
    color: #555;
}

.services .title::after {
    content: '';
    background-color: #00bdfe;
    width: 80px;
    height: 5px;
    border: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}


.container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
