.aboutus {
    /*margin-bottom: 4rem;*/
}

.aboutus .title {
    text-align: center;
    margin: 0 auto 80px;
    position: relative;
    line-height: 60px;
    color: #555;
}

.aboutus .title::after {
    content: '';
    background-color: #00bdfe;
    width: 80px;
    height: 5px;
    border: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.container {
    padding: 1rem;
    align-items: center;
    display: flex;
}

.image-container {
    flex: 1;
    width: 50%;
}

.image-container img {
    width: 80%;
    max-height: 400px;
    align-self: center;
}

.text-container {
    justify-content: center;
    text-align:left;
    flex: 1;
}

.text-container h2 {
    align-self: center;
    font-size: 23px;
    color: #555;
    letter-spacing: 1.3px;
}

@media screen and (max-width: 800px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .image-container img {
        width: 100%;
        max-height: 450px;
        align-self: center;
    }

    .text-container {
        margin-top: 20px;
    }

    .text-container h2 {
        align-self: center;
        font-size: 15px;
        color: #555;
        text-align: center;
    }
    .image-container {
        flex: 1;
        width: 80%;
    }
    .image-container img {
        width: 100%;
    }
}
