

.__container {
    max-width: 1080px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /*flex-wrap: wrap;*/
    padding: 1rem;
}

.contact-us .title {
    text-align: center;
    position: relative;
    line-height: 60px;
    color: #555;
}

.contact-us .title::after {
    content: '';
    background-color: #00bdfe;
    width: 80px;
    height: 5px;
    border: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.contact-us .description {
    text-align: center;
    margin: 30px 0;
}

.contact-us .__container .form-container {

}


.contact-us .__container .form-container .information {
    /*width: 300px;*/
    /*border: 1px solid #d6d7da;*/
    /*padding: 10px;*/
    /*border-radius: 5px;*/
    /*font-family: arial;*/
    /*line-height: 16px;*/
    /*color: #333333;*/
    /*font-size: 14px;*/
    /*background: #ffffff;*/
    /*margin-top: 5rem;*/
    /*align-self: flex-end;*/
}

.alert-info {
    border: 1px solid #b1e6f8;
    padding: 1rem;
    border-radius: 5px;
}

.form-container {
    width: 700px;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 6px 20px rgb(56 125 255 / 17%);
}

.form-container ._information form label,
form input, form select, form textarea {
    display: block;
    /* //margin-bottom: 10px; */
    width: 100%;
    align-self: center;
}



.form-container ._information form label {
    font-size: 14px;
    margin-top: 10px;
}

.form-container ._information form input, form select, form textarea {
    padding: 5px;
    border: solid 1px #BDC7D8;
    margin-top: 5px;
    line-height: 2rem;
    border-radius: 5px;
    -webkit-appearance: menulist-button;
    -moz-appearance:none;
}

.form-container ._information form select {
    height: 45px;
}

.form-container ._information form textarea {
    resize: vertical;
}

.form-container ._information .button {
    background-color: #00BFFF;
    cursor: pointer;
    font-weight: bold;
    padding: 12px 15px;
    color: #ffffff;
    margin: 15px 0;
    border-radius: 10px;
    font-size: 16px;
}

.button:disabled,
.button[disabled]{
  background-color: #cccccc !important;
  color: #666666 !important;
}

.form-container ._information .button:hover {
    background-color: #0044ff;
}