.header {
    background: radial-gradient(#fff,#ADD8E6);
}
/* .header .row {
    margin-top: 30px;
} */

.header .container {
    display: flex;
    flex-flow: column;
}

/* .navbar {
    display: flex;
    align-items: center;
    background: none;
    justify-content: space-between;
    width: 100%;
} */

nav {
    display: flex;
}

nav ul {
    display: inline-block;
    list-style-type: none;
}

nav ul li {
    display: inline-block;
}

a {
    text-decoration: none;
    color: #555;
}

p {
    color: #555;
}

.header .container .row {
    align-items: center;
}

.col-2 {
    width: 50%;
    text-align: left;
}

.col-2 img {
    max-width: 100%;
    padding: 20px 0;
}

.col-2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 25px 0;
}

.header .btn {
    display: inline-block;
    background: #ff523b;
    color: #fff;
    padding: 8px 30px;
    margin: 30px 0;
    border-radius: 30px;
    transition: background 0.5s;
}
.btn:hover {
    background: #563434;
}