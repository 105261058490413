.card {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 300px !important;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    margin: 10px 15px;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    border-radius: 10px;
    overflow: hidden;
}

.card-icon {
    display: flex;
    justify-content: center;
}

.card > svg {
}

.card h2 {
    margin: 15px 0;
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
}

.card p {
    font-size: 16px;
    color: #252e48;
    padding: 0 25px;
    text-align: center;
}