.information {
    margin-bottom: 4rem;
}

.information .title {
    text-align: center;
    margin: 0 auto 80px;
    position: relative;
    line-height: 60px;
    color: #555;
}

.information .title::after {
    content: '';
    background-color: #00bdfe;
    width: 80px;
    height: 5px;
    border: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.container {
    display: flex;
    align-items: center;
}